import React from "react";
// import GraphIcon from "../../images/pic.png";

function Test() {
  return (
    <>
      {/* <section className="section is-primary"> */}
        <div className="container-about">
          <div className="blog-card">
            <div className="image-wrapper">
              <div
                className="image"
                style={{
                  "backgroundImage":"url('https://appinventiv.com/blog/wp-content/uploads/2019/01/The-Power-of-Fintech-for-your-business.jpg')",
                }}
              ></div>
            </div>
            <div className="description">
              <h1>What is McMaster FinTech?</h1>
              {/*Yeah idk what to put here*/}
              <h2>We are a club at McMaster University</h2>
              <p>
                McMaster FinTech prides itself in providing experiential
                opportunities for students to develop a diverse and essential
                skillset moving into the workplace. It is our mission to enrich
                the McMaster student body expertise and develop an innovative
                FinTech culture ready for the future. Read more below to find out how you can get involved with McMaster FinTech.{" "}
              </p>
              <p className="card-actions">
                {/* <a href="#" className="has-text-grey">
                  Learn More!
                  <i className="fa fa-chevron-down" aria-hidden="true"></i>
                </a> */}
              </p>
            </div>
            {/* </div> */}
          </div>
        </div>
      {/* </section> */}
    </>
  );
}

export default Test;
