import React from "react";
// import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import HeaderLogo from "../images/Logov1trans.png";

function Header(props) {
  function handleLogout() {
    try {
      Auth.signOut();
      props.authProps.setAuthStatus(false);
      props.authProps.setUser(null);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>

      <header className="header-main">


        {/* <img className="header-logo" src="https://placeholder.com/wp-content/uploads/2018/10/placeholder.com-logo1.png" alt="Mac FinTech Logo"/> */}
        {/* <h3 className="header-logo">McMaster FinTech</h3> */}
        <a href="/">
          <img class="header-logo"
            src={HeaderLogo} style={{ height: "auto", width: "35px" }} alt="McMaster FinTech logo" />

        </a>

        <nav>
          <ul  class="nav-links">

          <li>
              <a href="/">About</a>
          </li>

            <li>
              <a href="/events">Fall 2022 Events</a>
            </li>
            <li>
              <a href="/Contact">Contact</a>
            </li>

            {/* <a href="/">News</a> */}
        
            {/* <li>
              <a href="/storefront">Store</a>
            </li> */}
            {/* <li>
              <a href="/blog">Blog & Forum</a>
            </li> */}
            {/* <li>
              <a href="/team">The Team</a>
            </li>  */}

            {/* <li>
              <a href="/apply">Apply</a>
            </li> */}
            {/* <li>
              <a href="/sponsors">Sponsors</a>
            </li> */}
          </ul>
        </nav>

        {props.authProps.isAuthenticated &&
          props.authProps.user &&
          props.authProps.user.username ? (
          <div className="right-content-logged-in">
            <h4>Hello, {props.authProps.user.username}!</h4>
            <button onClick={() => handleLogout()}>Logout</button>
          </div>
        ) : (
          <div id="Sign-up" class="right-content">
            <a href="/login">
              <button>Sign Up</button>
            </a>
          </div>
        )}
        
      </header>


    </>
  );
}

export default Header;
