import React from "react";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router";
import axios from "axios";
import config from "../config.json";

function LogIn(props) {
  const [loginUser, setLoginUser] = React.useState({
    username: "",
    password: "",
    logged_in: false,
  });

  const [registerUser, setRegisterUser] = React.useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    isRegistered: false
  });

  const [errorMessageLogin, setErrorMessageLogin] = React.useState("");
  const [errorMessageRegister, setErrorMessageRegister] = React.useState("");


  function handleChangeLogin(e) {
    const { name, value } = e.target;

    setLoginUser({
      ...loginUser,
      [name]: value,
    });
  }

  function handleChangeRegister(e) {
    const { name, value } = e.target;

    setRegisterUser({
      ...registerUser,
      [name]: value,
    });
  }

  async function handleSubmitLogin(e) {
    e.preventDefault();
    setErrorMessageLogin("");

    try {
      const user = await Auth.signIn(loginUser.username, loginUser.password);
      props.authProps.setAuthStatus(true);
      props.authProps.setUser(user);

      let user_id = user.attributes.sub;

      axios.get(`${config.api.invokeUrl}/user/${user_id}`).then((user) => {
        let user_data = user.data.Item;
        props.authProps.fetchData(user_data.fins.N, user_data.email.S);
      });

      setLoginUser((prev) => ({
        ...prev,
        logged_in: true,
      }));

      if (errorMessageLogin !== "") setErrorMessageLogin("")

    } catch (error) {
      // Handle error
      setErrorMessageLogin(error.message);

    }
  }

  async function handleSubmitRegister(e) {
    e.preventDefault();
    setErrorMessageRegister("");

    const { username, email, password, confirmPassword} = registerUser;

    if (password === confirmPassword) {
      try {
        const signUpResponse = await Auth.signUp({
          username,
          password,
          attributes: {
            email: email,
          },
        });

        setRegisterUser((prev) => ({...prev, isRegistered: true}))
        
      } catch (error) {
        // console.log("Error caught: " + error)
        if (error.message.includes("length")) setErrorMessageRegister("Password must contain 6 characters")
        else if (error.message.includes("uppercase")) setErrorMessageRegister("Password must contain an uppercase")
        else if (error.message.includes("lowercase")) setErrorMessageRegister("Password must contain a lowercase")
        else if (error.message.includes("number")) setErrorMessageRegister("Password must contain a number")
        else setErrorMessageRegister(error.message)
      }
    } else {
      // console.log("Passwords do not match")
      setErrorMessageRegister("Passwords do not match.")
    }

  }

  function login() {
    let login_div = document.getElementById("login");
    let register_div = document.getElementById("register") ;
    let btn_div = document.getElementById("btn");

    login_div.style.left = "50px";
    register_div.style.left="450px";
    btn_div.style.left="0"
  }

  function register() {
    let login_div = document.getElementById("login");
    let register_div = document.getElementById("register") ;
    let btn_div = document.getElementById("btn");

    login_div.style.left="-400px";
    register_div.style.left="50px";
    btn_div.style.left="110px";
  }
  return (
    <>
      {loginUser.logged_in && <Redirect to="/" />}     
        <>
        <div class = "animation-area">
                <ul class = "box-area">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
              </ul>
             </div>
          <div className="login">
            <div className="form-box">
            {registerUser.isRegistered ? (
              <>
                <h2 style={{"color": "orange","font-size":"25px"}}>Thank you for registering an account for McMaster FinTech!</h2>
                <p style={{"font-size": "15px"}}>Please check your email for a confirmation link. This will allow you to log in using your username</p>
              </>
            ) : (
              <>
              <div className="button-box">
                <div id="btn"></div>
                <button type="button" className="toggle-btn" onClick={() => login()}>
                  Log In
                </button>
                <button type="button" className="toggle-btn" onClick={() => register()}>
                  Register
                </button>
              </div>

              <div className="login-logo"></div>

              <form className="input-group" id="login" onSubmit={(e) => handleSubmitLogin(e)}>
                <h3 style={{color: "orange"}}>Log in using your username</h3>
                <input type="text" name="username" className="input-field" placeholder="Enter your username" onChange={handleChangeLogin} required></input>
                <input type="password" name="password" className="input-field" placeholder="Enter your password" onChange={handleChangeLogin} required></input>
                <button type="submit" className="submit-btn">Log In</button>
                {errorMessageLogin === "" ? null : <p id="error-text" style={{"font-size":"15px"}}>{errorMessageLogin}</p>}
                <p style={{"font-size":"12px", "padding": "10px", "color":"#838383"}}>Don't have an account? Click "Register" at the top of this box to register for a free account.</p>
              </form>

              <form className="input-group" id="register" onSubmit={(e) => handleSubmitRegister(e)}>
                <h3 style={{color: "orange", fontSize: "16px"}}>Register to be a general member</h3>
                <input type="text" name="username" className="input-field" placeholder="Enter a username" onChange={handleChangeRegister} required></input>
                <input type="email" name="email"className="input-field" placeholder="Enter your email" onChange={handleChangeRegister} required></input>
                <input type="password" name="password" className="input-field" placeholder="Enter your password" onChange={handleChangeRegister} required></input>
                <input type="password" name="confirmPassword" className="input-field" placeholder="Re-enter your password" onChange={handleChangeRegister} required></input>
                <button type="submit" className="submit-btn">Register</button>
                {errorMessageRegister === "" ? null : <p id="error-text" style={{"font-size":"15px"}}>{errorMessageRegister}</p>}
              </form>
              </>
              )}
            </div>
          </div>
        </>
    </>
  );
}

export default LogIn;
