import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import config from "../config.json"
function Footer() {
    return (
        <>
            <footer>
                <div className="footer-container">
                    <div className="left-col">
                        {/*Logo goes here*/}
                        <h2>McMaster FinTech</h2>
                        <div className="social-media">
                            {/*Replace with social media links*/}
                            <a href="https://facebook.com/macfintech"><i className="fab fa-facebook-f"></i></a>
                            {/* <a href="/"><i className="fab fa-twitter"></i></a> */}
                            <a href="https://instagram.com/macfintech"><i className="fab fa-instagram"></i></a>
                            {/* <a href="/"><i className="fab fa-youtube"></i></a> */}
                            <a href="https://www.linkedin.com/company/mcmaster-fintech"><i className="fab fa-linkedin-in"></i></a>
                            <p className="rights-text">McMaster FinTech 2022</p>
                        </div>
                    </div>
                    <div className="right-col">
                        <h1>Our Newsletter</h1>
                        <div className="border"></div>
                        <p>Register to be a general member by signing up for our newsletter</p>
                        <div className="newsletter-form">
                            {/* <input type="email" name="email" className="txtb" placeholder="Enter your email"></input>
                            <input type="submit" className="btn" value="submit"></input> */}
                            <MailchimpSubscribe url={config.newsletter.MAILCHIMP_URL} />
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;