import React from "react";
// import SignUp from "./components/SignUp.jsx";
import Header from "./components/Header.jsx";
import LogIn from "./components/LogIn.jsx";
import ThankYou from "./components/StoreComponents/Store.ThankYou.jsx";
import About from "./components/AboutComponents/About.jsx";
import Team from "./components/TeamComponents/Team.jsx";
import Sponsors from "./components/Sponsors.jsx";
import BlogMain from "./components/BlogComponents/Blog.Main.jsx";
import Contact from "./components/Contact.jsx";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";
import config from "./config.json";
import StoreFrontPage from "./components/StoreComponents/Store.FrontPage";
import EventsMain from "./components/EventComponents/Events.Main.jsx";
import ExecApply from "./components/ExecApply.jsx";
import Contacts from "./components/Contacts.jsx";
import Gen404 from "./component/Gen404.jsx";
import NewEvents from "./components/NewEvents.jsx";
// import Footer from "./components/Footer.jsx";

function App() {
  const [curUser, setCurUser] = React.useState({
    isAuthenticated: false,
    user: null,
    isFetching: true,
    fins: null,
    email: null,
  });

  // Runs every time a new page is loaded, checks if user is logged in
  React.useEffect(() => {
    try {
      // Retrieve session from local storage
      Auth.currentSession().then((userSession) => {
        if (userSession) {
          setAuthStatus(true);
          console.log("Retrieved user session");
        } else {
          console.log("Could not retrieve user session");
        }
      });

      // Retrieve user
      Auth.currentAuthenticatedUser().then((user) => {
        if (user) {
          setUser(user);
          console.log("Retrieved user");

          let user_id = user.attributes.sub;
          axios.get(`${config.api.invokeUrl}/user/${user_id}`).then((user) => {
            console.log("Axios get user");
            let user_data = user.data.Item;
            setCurUser((prev) => ({
              ...prev,
              fins: user_data.fins.N,
              email: user_data.email.S,
            }));
          });
        } else {
          console.log("Could not retrieve user");
        }
      });
    } catch (err) {
      console.log(err);
    }

    setCurUser((prev) => {
      return {
        ...prev,
        isFetching: false,
      };
    });
  }, []);

  function setAuthStatus(authenticated) {
    setCurUser((prev) => {
      return {
        ...prev,
        isAuthenticated: authenticated,
      };
    });
  }

  function setUser(new_user) {
    setCurUser((prev) => {
      return {
        ...prev,
        user: new_user,
      };
    });
  }

  function fetchData(fins, email) {
    setCurUser((prev) => {
      return {
        ...prev,
        fins: fins,
        email: email,
      };
    });
  }

  const authProps = {
    ...curUser,
    setAuthStatus: setAuthStatus,
    setUser: setUser,
    fetchData: fetchData,
  };

  return (
    <>
      {curUser.isFetching ? (
        <p>Loading</p>
      ) : (
        <Router>
          {<Header authProps={authProps} />}
          <Switch>
            <Route exact path="/login" render={() => <LogIn authProps={authProps} />} />
            {/* <Route
              exact
              path="/storefront"
              render={() => <StoreFrontPage authProps={authProps} />}
            /> */}
            <Route exact path="/" render={() => <About />} />
            {/* <Route exact path="/blog" render={() => <BlogMain />} /> */}
            <Route exact path="/events" render={() => <NewEvents />} />
            {/* <Route exact path="/team" render={() => <Team />} /> */}
            {/* <Route exact path="/sponsors" render={() => <Sponsors />} /> */}
            <Route exact path="/Contact" render={() => <Contact />} />
            {/* <Route exact path="/thankyou" render={() => <ThankYou />} /> */}
            {/* <Route exact path="/apply" render={() => <ExecApply />} /> */}
            {/*<Route exact path="/Contact" render={() => <Contacts />} />*/}
            <Route exact path="/404" render={() => <Gen404 />} />
            <Redirect from="*" to="/404" />
          </Switch>
        </Router>
      )}
    </>
  );
}

export default App;
