import React from "react";
import emailjs from "emailjs-com";
import config from "../config.json";
import Footer from "./Footer";

function Contact() {
  const [userClickedSubmitted, setUserClickedSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  // send email to server (via emailJS -> forwards to macfintech@outlook.com)
  function submitForm(e) {
    e.preventDefault();
    console.log("User submitted form");

    setUserClickedSubmitted((prev) => true);

    const service_id = config.email.CONTACT_US_SERVICE_ID;
    // const service_id ="123";
    const template_id = config.email.CONTACT_US_TEMPLATE_ID;
    const user_id = config.email.EMAILJS_USER_ID;
    setErrorMessage("");
    emailjs.sendForm(service_id, template_id, e.target, user_id).then(
      (res) => {
        console.log(res.text);
        if (res.text === "OK") {
          setSubmitted((prev) => {
            return !prev;
          });
          setErrorMessage("");
        }
      },
      (err) => {
        console.log(err.text);
        setUserClickedSubmitted((prev) => false);
        setErrorMessage("There was an error, please try again later.");
      }
    );
  }

  return (
    <>
      <div className="bodyy">
        <div className="Contactt">
          <div className="contentt">
            {/*<h2>Lets Connect</h2>*/}
            {/* <p>Please feel free to contact us about any questions or concerns you may have. Our team will get back to you within 48 hours.</p> */}
          </div>

          <div className="Containerr">
            <div className="ContactInfoo">
              <h2>Contact Us</h2>

              <div className="boxx">
                <div className="iconn">
                  {" "}
                  <i className="card-icon fas fa-map-marker-alt"></i>{" "}
                </div>
                <div className="texttt">
                  <h3>Address</h3>
                  <p>
                    1280 Main Street West
                    <br></br>
                    Hamilton,ON
                    <br></br>
                    L8S 4L8
                  </p>
                </div>
              </div>

              <div className="boxx1">
                <div className="iconn">
                  {" "}
                  <i className="card-icon far fa-envelope"></i>{" "}
                </div>
                <div className="texttt">
                  <h3>General Inquiries</h3>
                  <p>macfintech@outlook.com</p>
                </div>
              </div>

              <div className="boxx2">
                <div className="iconn">
                  {" "}
                  <i className="card-icon fa fa-twitter"></i>{" "}
                </div>
                <div className="texttt">
                  <h3>Social Media</h3>

                  <div className="socialsbutton">
                    <div className="socialsbuttonFB">
                      <a
                        className="iconn2"
                        href=" https://www.facebook.com/MacFinTech"
                        target="_blank"
                      >
                        <i className="card-icon fa fa-facebook"></i>
                      </a>
                    </div>

                    <div className="socialsbuttonIG">
                      {" "}
                      <a
                        className="iconn3"
                        href="https://www.instagram.com/macfintech/"
                        target="_blank"
                        rel="https://www.instagram.com/"
                      >
                        {" "}
                        <i className="card-icon fa fa-instagram"></i>{" "}
                      </a>{" "}
                    </div>
                    <div className="socialsbuttonIG">
                      {" "}
                      <a
                        className="iconn4"
                        href="https://www.linkedin.com/company/mcmaster-fintech/"
                        target="_blank"
                      >
                        {" "}
                        <i className="card-icon fa fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="contactForm">
              {submitted ? (
                <p>
                  We have received your email and we will be responding to you
                  shortly. In the meantime, check out our events page to see
                  what we are up to!
                </p>
              ) : (
                <form
                  onSubmit={(e) => {
                    submitForm(e);
                  }}
                >
                  <h2>Send Message</h2>
                  <p id="error-text">{errorMessage}</p>
                  <div className="InputBox">
                    <input type="text" name="fName" required="required"></input>
                    <span>First Name</span>
                  </div>
                  <div className="InputBox">
                    <input type="text" name="lName" required="required"></input>
                    <span>Last Name</span>
                  </div>
                  <div className="InputBox">
                    <input type="text" name="email" required="required"></input>
                    <span>Email</span>
                  </div>
                  <div className="InputBox">
                    <div className="Phone">
                      <input type="text" name="phone" required></input>
                      <span>Phone</span>
                    </div>
                  </div>
                  <div className="InputBox">
                    <textarea name="message" required="required"></textarea>
                    <span>Type your Message...</span>
                  </div>
                  <div className="InputBox">
                    <input
                      type="submit"
                      value="Send"
                      className={
                        userClickedSubmitted ? "contact-submit-disabled" : ""
                      }
                    ></input>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Contact;
