import React from "react";

function ThankYou() {
  //   React.useEffect(() => {
  //     window.location.reload(false);
  //   }, []);

  return (
    <>
      <h1>Thank you for your purchase!</h1>
      <h3>
        Please check your account dashboard for purchase confirmation. You will
        receive an email about your order shortly.
      </h3>
    </>
  );
}

export default ThankYou;
