import React from "react";

function BlogMain() {
    return(
        <>
        <div className="blog-main">
            <h1>McMaster FinTech Blog!</h1>
            <p>Some blog stuff...</p>
        </div>
        </>
    );
}

export default BlogMain;