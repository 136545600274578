import React from "react";
import Particles from "react-particles-js";
import AboutImg from "../../images/about-img-1.png"

function AboutMain() {
  return (
    <>
      <section id="about-main-sec">
        <div className="about-main">
          <div className="container">
            <div className="about-info">
              <h2>McMaster FinTech</h2>
              <p style={{fontSize:"20px"}}>
              {/* Insert slogan here here here here here here here here here */}
              </p>
              <a href="/events"><button style={{margin:"20px"}}>Get started!</button></a>
            </div>

            {/* <div className="about-img"></div> */}
            <img src={AboutImg} id="main-img" alt="Graph of apple stock" />          
            </div>
          <div className="wave"></div>
          
        </div>
        <div style={{"width":"100%","position":"absolute","top":"1vh"}}>
          <Particles
          height="100vh"
          params={{
            "interactivity": {
                "detect_on": "window",
                "events": {
                    "onhover": {
                        "enable": true,
                        "mode": "repulse"
                    },
                    "onclick": {
                        "enable": false,
                        "mode": "bubble"
                    },
                    "resize": true
                },
                "modes": {
                    "grab": {
                        "distance": 400,
                        "line_linked": {
                            "opacity": 1
                        }
                    },
                    "bubble": {
                        "distance": 400,
                        "size": 40,
                        "duration": 2,
                        "opacity": 8,
                        "speed": 3
                    },
                    "repulse": {
                        "distance": 100,
                        "duration": 0.4
                    },
                    "push": {
                        "particles_nb": 4
                    },
                    "remove": {
                        "particles_nb": 2
                    }
                }
            },
            "retina_detect": true,
            "particles":{
              "number":{
                "value": 150,
                "density":{
                  "enable": true,
                  "value_area":"1803.4120608655228"
                }
              }
            }
          }} />
      </div>
      </section>
    </>
  );
}

export default AboutMain;
