import React from "react";
// import Footer from "./Footer.jsx";

function NewEvents() {


  return (
    <>
      <div class="NewEvents">
        <div class="OuterTitleDiv">
          <div class="TitleDiv">
            <span class="text1">Welcome to Events</span>
            <span class="text2">Fall 2022</span>

          </div>
          <h1 class="NewEventsTitle">Events coming soon!</h1>
        </div>
        <div class="animation-area2">
          <ul class="box-area">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>



      {/* <Footer /> */}
    </>
  );
}
export default NewEvents;