import React from "react";
import AboutMain from "./About.Main.jsx";
// import AboutDescription from "./About.Description.jsx";
// import AboutReasons from "./About.Reasons.jsx";
// import Contact from "../Contact.jsx";
import Footer from "../Footer.jsx";
import AboutFins from "./AboutFins.jsx";
import Test from "./test.jsx";
import AboutReasons2 from "./About.Reasons.v2.jsx";
// import AboutExtra from "./About.Extra.jsx";

function About() {
  return (
    <div className="about">
      <AboutMain />
      <Test/>
      <AboutReasons2/>
      <AboutFins />
      <Footer />
    </div>
  );
}
  
export default About;