import React from "react";

function AboutReasons2(){
    return(
    <>
    
    <div className="abreasons-wrapper">
        <div className="container-abreasons">
        {/* <h1>What does McMaster FinTech do?</h1> */}
            <div className="card">
                <div className="circle">
                    <h2> </h2>
                </div>
                <div className="content">
                    <h3>Industry Guest Speakers</h3>
                    <p>Ever wondered what it's like to actually work in the industry? Hear directly from our guest speakers and gain the insight you need to stand out.</p>
                    <a href="/events">Read more</a>
                </div>
            </div>

            <div className="card">
                <div className="circle">
                    <h2> </h2>
                </div>
                <div className="content">
                    <h3>Peer to Peer Workshops</h3>
                    <p>Tired of studying for classes that teach outdated information? Learn pragmatic skills, like earning money with stocks, at our online live workshops!</p>
                    <a href="/events">Schedule</a>
                </div>
            </div>

                        <div className="card">
                <div className="circle">
                    <h2> </h2>
                </div>
                <div className="content">
                    <h3>Competitions</h3>
                    <p>Think you're good at problem solving? Hone your skills with McMaster FinTech's bi-weekly online coding competitions and climb the leaderboards.</p>
                    <a href="/events">Compete!</a>
                </div>
            </div>

        </div>
    </div>
    </>
    );
}

export default AboutReasons2;