import React from "react";

function Gen404(props) {
  return (
    <>
        <div id='gen404-container'>
            <h1 id='header'>Sorry!</h1>
            <h3 id='body'>We cannot find the page that you requested!</h3>
        </div>
    </>
  );
}

export default Gen404;
