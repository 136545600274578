import React from "react";
// import CoinSpinning from "../../images/coin-gif.gif";

function AboutFins() {
  return (
    <>
      <div className="container-about">
        <div className="blog-card">
          <div className="description">
            <h1>What is FinTech?</h1>
            {/*Yeah idk what to put here*/}
            <h2>Simply put - Finanical technology</h2>
            <p>
              Paying for your coffee with Apple Pay at your local Tim Hortons may seem like a routine task to you, but
              it is advancements like these that allowed the finanical services industry be revolutionized.
              FinTech is a broad term - it encompasses any area that allows business 
              owners, companies, and consumers manage and automate financial processes
              & operations via the use of software and specialized algorithms.  <br></br> <br></br>
              
              FinTech is an emerging industry that has disrupted many fields like mobile payments,
               online banking, peer to peer transactions, blockchain & cryptocurrencies, and more. Discover even 
               more unique applications of FinTech through McMaster FinTech's peer to peer workshops, industry presentations, and
               learn the skills necessary to break into one the fastest growing industry of our times.
            </p>
            <p className="card-actions">
              {/* <a href="#" className="has-text-grey">
                  Learn More!
                  <i className="fa fa-chevron-down" aria-hidden="true"></i>
                </a> */}
            </p>
          </div>

          <div className="image-wrapper abfins">
            <div
              className="image"
              style={{
                backgroundImage: "url('https://www.navitv.net/wp-content/uploads/2019/02/image_810xs.gif')",
              }}
            ></div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default AboutFins;
